import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { getImageUrl } from "takeshape-routing";
import axios from "axios";
import PageTransition from "gatsby-plugin-page-transitions";
import { Container, Row, Col, Image } from "react-bootstrap";
import Layout from "../components/Layout/Layout";
import Section from "../components/Section/Section";
import SEO from "../components/Seo/Seo";
import { H2 } from "../components/Typography/Typography";

const MediaPage = ({ data }) => {
  const page = data?.takeshape?.getPhotoGallery || {};

  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const YOUTUBE_PLAYLIST_ITEMS_API =
      "https://www.googleapis.com/youtube/v3/search";

    axios
      .get(
        `${YOUTUBE_PLAYLIST_ITEMS_API}?type=video&order=date&part=snippet,id&maxResults=50&channelId=UCC21lDEdmEIE4ha7F76_X2w&key=${process.env.GATSBY_YOUTUBE_API_KEY}`
      )
      .then(res => {
        const videos = res.data;
        const videosList = videos.items;
        setVideos(videosList);
      });
  }, []);

  return (
    <PageTransition>
      <Layout>
        <SEO title="Media" />
        <Section className="sf-media">
          <Container>
            <Row>
              <Col>
                <H2>Photos</H2>
              </Col>
            </Row>
            <Row>
              {page.photos.map((image, key) => (
                <Col key={key} className="mt-4" md={4} sm={6}>
                  <Image
                    className="img-fluid"
                    src={getImageUrl(image.photo, {
                      w: 500,
                      h: 350,
                      fit: "crop"
                    })}
                    alt=""
                  />
                </Col>
              ))}
            </Row>

            <Row>
              <Col>
                <H2 className="mt-5" id="video">
                  Videos
                </H2>
              </Col>
            </Row>

            <Row>
              {videos.map(({ id, snippet = {} }, key) => {
                const { title, thumbnails = {} } = snippet;
                const { high } = thumbnails;
                const { videoId } = id;
                return (
                  <Col className="mt-4" md={4} sm={6} key={key}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://www.youtube.com/watch?v=${videoId}`}
                      className="video"
                    >
                      <Image
                        className="img-fluid"
                        width={high.width}
                        height={high.height}
                        src={high.url}
                        alt={title}
                      />
                      <span>{title}</span>
                    </a>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </Section>
      </Layout>
    </PageTransition>
  );
};

export default MediaPage;

export const pageQuery = graphql`
  query {
    takeshape {
      getPhotoGallery {
        photos {
          photo {
            path
          }
        }
      }
    }
  }
`;
